import { axios } from '@/utils/request'
const basic = '/manage/task'
const api = {
  // 分页查询任务
  findTask: basic + '/findTask',
  // 添加外呼任务
  addTask: basic + '/addTask',
  // 上传待呼号码
  uploadNumber: basic + '/uploadNumber',
  // 删除外呼任务
  delTask: basic + '/delTask',
  // 查看任务详情
  detailTask: basic + '/detail',
  // 修改任务状态
  updateTaskStatus: basic + '/updateTaskStatus'
}
export function findAll(parameter) {
  return axios({
    url: api.findTask,
    method: 'post',
    params: parameter
  })
}
export function addTask(parameter) {
  return axios({
    url: api.addTask,
    method: 'post',
    data: parameter
  })
}
export function delTask(parameter) {
  return axios({
    url: api.delTask,
    method: 'post',
    params: parameter
  })
}
export function uploadNumber(parameter) {
  return axios({
    url: api.uploadNumber,
    method: 'post',
    params: { id: parameter.id },
    data: parameter.file,
    timeout: 0
  })
}
export function detailTask(parameter) {
  return axios({
    url: api.detailTask,
    method: 'post',
    params: parameter
  })
}
export function updateTaskStatus(parameter) {
  return axios({
    url: api.updateTaskStatus,
    method: 'post',
    params: parameter
  })
}
