<template>
  <keep-alive include="ListTaskComponent">
    <component :is="component" @cut="cut" :title="title" :id="id"></component>
  </keep-alive>
</template>

<script>
import ListTaskComponent from '@/views/outbound/task/children/ListTaskComponent'
import EditTaskComponent from '@/views/outbound/task/children/EditTaskComponent'
import PhoneList from '@/views/outbound/task/children/PhoneList'
export default {
  name: 'TaskManageView',
  components: {
    ListTaskComponent,
    EditTaskComponent,
    PhoneList
  },
  data () {
    return {
      component: 'ListTaskComponent',
      id: '',
      title: ''
    }
  },
  methods: {
    cut (name, type, data) {
      this.id = data
      this.title = type
      this.component = name
    }
  }
}
</script>

<style scoped>

</style>
