import { axios } from '@/utils/request'

const basic = '/manage/skill'
const api = {
  // 分页查询话术
  find: basic + '/find',
  // 查询所有话术
  findAll: basic + '/findAll',
  // 添加话术
  add: basic + '/add',
  // 删除话术
  del: basic + '/del'
}
export function find (parameter) {
  return axios({
    url: api.find,
    method: 'get',
    params: parameter
  })
}
export function findAll (parameter) {
  return axios({
    url: api.findAll,
    method: 'get',
    params: parameter
  })
}
export function del (parameter) {
  return axios({
    url: api.del,
    method: 'delete',
    params: parameter
  })
}
export function add (parameter) {
  return axios({
    url: api.add,
    method: 'post',
    data: parameter
  })
}
