import { axios } from '@/utils/request'
const basic = '/manage'
const api = {
  // 查询所有外显号码
  findAllExplicit: basic + '/explicit/findAllExplicit',
  // 查询所有小组
  findAllOrganization: basic + '/organization/findAllOrganization',
  // 查询所有模板
  findAllTemplate: basic + '/template/findAllTemplate',
  // 查询所有项目
  findAllProject: basic + '/project/findAll',
  // 查询所有外呼任务
  findAllTask: basic + '/task/findAllTask',
  // 查询所有用户
  findAllUserList: basic + '/user/findAllUserList',
  // 查询该小组所有用户
  findOrganizationUser: basic + '/organization/findOrganizationUser'
}
export function findAllExplicit (parameter) {
  return axios({
    url: api.findAllExplicit,
    method: 'post',
    params: parameter
  })
}
export function findAllOrganization (parameter) {
  return axios({
    url: api.findAllOrganization,
    method: 'post',
    params: parameter
  })
}
export function findAllTemplate (parameter) {
  return axios({
    url: api.findAllTemplate,
    method: 'post',
    params: parameter
  })
}
export function findAllProject (parameter) {
  return axios({
    url: api.findAllProject,
    method: 'post',
    params: parameter
  })
}
export function findAllTask (parameter) {
  return axios({
    url: api.findAllTask,
    method: 'post',
    params: parameter
  })
}
export function findAllUserList (parameter) {
  return axios({
    url: api.findAllUserList,
    method: 'get',
    params: parameter
  })
}
export function findOrganizationUser (parameter) {
  return axios({
    url: api.findOrganizationUser,
    method: 'post',
    params: parameter
  })
}
