<template>
  <a-card :bordered="false">
    <p class="title">{{ title }}外呼任务</p>
    <a-form-model ref="form" :model="form" :rules="rules" @submit="editHandleOk" class="ant-advanced-search-form">
      <a-row :gutter="24">
        <a-col :span="8">
          <a-form-model-item label="任务名称" prop="name" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
            <a-input v-model="form.name"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="项目名称" prop="projectId" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
            <a-select
              v-model="form.projectId"
              show-search
              :filter-option="filterOption"
              option-filter-prop="children"
              :disabled="disabledNameInput">
              <a-select-option
                v-for="(data,key) of allProject"
                :key="key"
                :value="data.id"
              >{{ data.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="外呼小组" prop="organizationId" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
            <a-select v-model="form.organizationId">
              <a-select-option
                v-for="(data,key) of allOrganization"
                :key="key"
                :value="data.id"
              >{{ data.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="营销状态模板" prop="templateId" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
            <a-select
              v-model="form.templateId"
              show-search
              :filter-option="filterOption"
              option-filter-prop="children"
              :disabled="disabledNameInput">
              <a-select-option
                v-for="(data,key) of allTemplate"
                :key="key"
                :value="data.id"
              >{{ data.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="外显号码" prop="explicitId" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
            <a-select v-model="form.explicitId">
              <a-select-option
                v-for="(data,key) of allExplicit"
                :key="key"
                :value="data.id"
              >{{ data.phone }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="通话等待时间" title="通话等待时间,超过此时间的坐席会进行调度,单位秒" prop="callSec" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
            <a-input v-model="form.callSec" placeholder="通话等待时间,超过此时间的坐席会进行调度,单位秒"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="控制参数" title="控制参数,用于控制此任务的呼叫频率，直接影响到每轮任务的总数,此值必须保证小于 1 大于 0" prop="controlRate" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
            <a-input v-model="form.controlRate" placeholder="控制参数,用于控制此任务的呼叫频率，直接影响到每轮任务的总数,此值必须保证小于 1 大于 0"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="排队等待时间" title="排队等待时间,超过此时间的坐席会进行调度,单位秒" prop="lineSec" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
            <a-input v-model="form.lineSec" placeholder="排队等待时间,超过此时间的坐席会进行调度,单位秒"/>
          </a-form-model-item>
        </a-col>
        <a-col :span="8">
          <a-form-model-item label="初始接听率" prop="answerRate" title="初始接听率，此值会进行计算，如果此值在开始的时候就设置为较准确的参数呼损率会降低，反之刚开始呼叫时会有呼损率，慢慢的趋于正常" :label-col="{ span: 6 }" :wrapper-col="{ span: 12 }">
            <a-input v-model="form.answerRate" placeholder="初始接听率，此值会进行计算，如果此值在开始的时候就设置为较准确的参数呼损率会降低，反之刚开始呼叫时会有呼损率，慢慢的趋于正常"/>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-model-item label="项目描述" :label-col="{ span: 2 }" :wrapper-col="{ span: 20 }">
            <a-textarea
              :maxLength="300"
              v-model="form.remark"
              placeholder="请输入项目描述"
              :autoSize="{ minRows: 3, maxRows: 5 }"
            />
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item prop="fileName" label="录音导出格式" :label-col="{ span: 2 }" :wrapper-col="{ span: 20 }">
            <a-row :gutter="24">
              <a-col
                class="col-box"
                :span="3"
                v-for="(data, key) of form.fileName"
                :key="key">
                <a-input
                  v-model="form.fileName[key]">
                </a-input>
                <a-icon
                  v-if="form.fileName.length > 1 && key !== 0"
                  class="dynamic-delete-button remove-ico"
                  type="minus-circle-o"
                  @click="removeInput(key)"
                  style="font-size: 15px;margin-left: 30px"
                />
              </a-col>
              <a-icon
                v-if="form.fileName.length < 4"
                class="dynamic-delete-button"
                type="plus-circle"
                :disabled="form.fileName.length === 4"
                @click="addInput()"
                style="font-size: 15px;margin-left: 30px"
              />
            </a-row>
          </a-form-model-item>
        </a-col>
        <!--        <a-col :span="24">-->
        <!--          <a-form-model-item label="话术" :label-col="{ span: 2 }" :wrapper-col="{ span: 20 }">-->
        <!--            <WangEditor @change="getEditor" v-model="form.talkSkill"></WangEditor>-->
        <!--          </a-form-model-item>-->
        <!--        </a-col>-->
        <a-col :span="24">
          <a-form-model-item label="话术名称" :label-col="{ span: 2 }" :wrapper-col="{ span: 5 }">
            <a-select
              v-model="form.talkSkillId"
              :disabled="title==='查看'"
              show-search
              :filter-option="filterOption"
              option-filter-prop="children"
              @change="patterChange">
              <a-select-option
                v-for="(data,key) of allSkill"
                :key="key"
                :value="data.id"
              >{{ data.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="24">
          <a-form-model-item label="话术内容" :label-col="{ span: 2 }" :wrapper-col="{ span: 20 }">
            <div style="line-height: normal">
              <div v-html="ueditContent" class="view"></div>
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <div class="footer">
      <a-button @click="editHandleOk" v-if="title!=='查看'">确定</a-button>
      <a-button @click="back">取消</a-button>
    </div>
  </a-card>
</template>

<script>
import WangEditor from '@/components/Editor/WangEditor'
import { addTask, detailTask } from '@/api/outbound/task'
import { findAllProject, findAllOrganization, findAllExplicit, findAllTemplate } from '@/api/findAll'
import { findAll } from '@/api/template/skill'

export default {
  name: 'EditTaskComponent',
  props: {
    id: {
      type: Number,
      default: null
    },
    title: {
      type: String,
      default: ''
    }
  },
  components: {
    WangEditor
  },
  data () {
    const validateFileName = (rule, value, callback) => {
      console.log(value)
      if (value.includes('')) {
        callback(new Error('录音导出格式不能有空值!'))
      } else {
        callback()
      }
      // if (value === '') {
      //   callback(new Error('Please input the password'));
      // } else {
      //   if (this.ruleForm.checkPass !== '') {
      //     this.$refs.ruleForm.validateField('checkPass');
      //   }
      //   callback()
      // }
    }
    return {
      form: {
        name: '',
        projectId: '',
        organizationId: '',
        templateId: '',
        explicitId: '',
        remark: '',
        talkSkillId: '',
        fileName: ['联系方式'],
        callSec: '60',
        controlRate: '0.3',
        lineSec: '15',
        answerRate: '0.45'
      },
      rules: {
        name: [
          { required: true, message: '任务名称不能为空', trigger: 'blur' }
        ],
        callSec: [
          { required: true, message: '通话等待时间不能为空', trigger: 'blur' }
        ],
        controlRate: [
          { required: true, message: '控制参数不能为空', trigger: 'blur' }
        ],
        lineSec: [
          { required: true, message: '等待时间不能为空', trigger: 'blur' }
        ],
        answerRate: [
          { required: true, message: '初始接听率不能为空', trigger: 'blur' }
        ],
        projectId: [
          { required: true, message: '项目不能为空', trigger: 'blur' }
        ],
        organizationId: [
          { required: true, message: '外呼小组不能为空', trigger: 'blur' }
        ],
        templateId: [
          { required: true, message: '营销状态模板不能为空', trigger: 'blur' }
        ],
        explicitId: [
          { required: true, message: '外显号码不能为空', trigger: 'blur' }
        ],
        remark: [
          { required: false, message: '任务名称不能为空', trigger: 'blur' }
        ],
        talkSkill: [
          { required: false, message: '任务名称不能为空', trigger: 'blur' }
        ],
        fileName: [
          { required: true, validator: validateFileName, trigger: 'change' }
        ]
      },
      // nameValidate: ['name', { rules: [{ required: true, message: '任务名称不能为空！' }] }],
      // projectIdValidate: ['projectId', { rules: [{ required: true, message: '项目不能为空！' }] }],
      // organizationIdValidate: ['organizationId', { rules: [{ required: true, message: '外呼小组不能为空！' }] }],
      // templateIdValidate: ['templateId', { rules: [{ required: true, message: '营销状态模板不能为空！' }] }],
      // explicitIdValidate: ['explicitId', { rules: [{ required: true, message: '外显号码不能为空！' }] }],
      // remarkValidate: ['remark', { rules: [{ required: false }] }],
      // talkSkillValidate: ['talkSkill', { rules: [{ required: false, message: '话术不能为空！' }] }],
      // fileNameValidate: ['fileName', { rules: [{ initialValue: ['联系方式'], required: true, message: '导出格式不能为空！' }] }],
      allProject: [],
      allOrganization: [],
      allExplicit: [],
      allTemplate: [],
      allSkill: [],
      detail: {},
      disabledNameInput: false,
      ueditContent: ''
    }
  },
  created () {
    this.findAll()
  },
  methods: {
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    async findAll () {
      await findAllProject().then(res => {
        if (res.status !== 200) {
          throw new Error(res.message)
        }
        this.allProject = res.body
      }).catch((e) => {
        this.$notification.error({
          message: '提示',
          description: e.message
        })
      })
      await findAllOrganization().then(res => {
        if (res.status !== 200) {
          throw new Error(res.message)
        }
        this.allOrganization = res.body
      }).catch((e) => {
        this.$notification.error({
          message: '提示',
          description: e.message
        })
      })
      await findAllExplicit().then(res => {
        if (res.status !== 200) {
          throw new Error(res.message)
        }
        this.allExplicit = res.body
      }).catch((e) => {
        this.$notification.error({
          message: '提示',
          description: e.message
        })
      })
      await findAllTemplate().then(res => {
        if (res.status !== 200) {
          throw new Error(res.message)
        }
        this.allTemplate = res.body
      }).catch((e) => {
        this.$notification.error({
          message: '提示',
          description: e.message
        })
      })
      await findAll().then(res => {
        if (res.status !== 200) {
          throw new Error(res.message)
        }
        this.allSkill = res.body
      }).catch((e) => {
        this.$notification.error({
          message: '提示',
          description: e.message
        })
      })
      if (this.id) {
        this.disabledNameInput = true
        await detailTask({ id: this.id }).then(res => {
          if (res.status !== 200) {
            throw new Error(res.message)
          }
          const data = res.body
          // this.form.setFieldsValue({
          //   name: data.name,
          //   remark: data.remark,
          //   projectId: data.projectId,
          //   organizationId: data.organizationId,
          //   templateId: data.templateId,
          //   explicitId: data.explicitNumberId,
          //   talkSkill: data.talkSkill
          // })
          data.explicitId = data.explicitNumberId
          data.talkSkillId = data.talkSkill.id
          this.ueditContent = data.talkSkill.talkSkill
          console.log(this.ueditContent)
          delete data.explicitNumberId
          if (data.fileName === undefined) {
            data.fileName = ['${' + '联系方式}']
          } else if (data.fileName.length === 0) {
            data.fileName = [data.fileName]
          } else {
            data.fileName = data.fileName.split('|')
          }
          const arr = []
          for (const i of data.fileName) {
            arr.push(i.slice(2, -1))
          }
          data.fileName = arr
          this.form = data
        })
      }
    },
    removeInput (key) {
      this.form.fileName.splice(key, 1)
    },
    addInput () {
      this.form.fileName.push('')
    },
    getEditor (value) {
      this.form.talkSkill = value
    },
    patterChange (id) {
      const arr = this.allSkill.filter(value => {
        return value.id === id
      })
      this.ueditContent = arr[0].talkSkill
    },
    editHandleOk () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.confirmLoading = true
          const arr = this.form.fileName.map((value) => {
            return '${' + value + '}'
          })
          const data = { ...this.form }
          data.fileName = arr.join('|')
          delete data.projectName
          delete data.templateName
          delete data.createTime
          delete data.status
          delete data.statusOutbound
          delete data.totalNumber
          delete data.validNumber
          delete data.outboundNumber
          delete data.remainingNumber
          delete data.connectionNumber
          delete data.lossNumber
          delete data.lossRate
          delete data.talkSkill
          if (data.talkSkillId === '') {
            delete data.talkSkillId
          }
          addTask({ ...{ id: this.id }, ...data }).then(res => {
            if (res.status !== 200) {
              this.$notification.error({
                message: '提示',
                description: res.message
              })
              return
            }
            this.$emit('cut', 'ListTaskComponent')
            this.$notification.success({
              message: '提示',
              description: res.message
            })
            this.$refs.form.resetFields()
          }).catch((e) => {
            this.$notification.error({
              message: '提示',
              description: e.message
            })
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    back () {
      this.$emit('cut', 'ListTaskComponent')
    }
  }

}
</script>

<style scoped>
  .ant-advanced-search-form .ant-form-item {
    display: flex;
  }

  .footer {
    text-align: center;
  }

  .footer button {
    margin: 0 30px;
  }

  .title {
    text-align: center;
    font-size: 30px;
  }

  .col-box {
    position: relative;
  }

  .remove-ico {
    position: absolute;
    top: 0;
    color: red;
    background: #ffffff;
    right: 6px;
    margin: 0 !important;
  }
  .view {
    border: 1px solid #ddd;
    padding: 10px 0;
  }
</style>
<style lang="less">
  .view {
    word-wrap: break-word;
    word-break: normal;
    .selectTdClass {
      background-color: #edf5fa !important
    }

    table.noBorderTable td, table.noBorderTable th, table.noBorderTable caption {
      border: 1px dashed #ddd !important
    }

    table {
      margin-bottom: 10px;
      border-collapse: collapse;
      display: table;
    }

    td, th {
      padding: 5px 10px;
      border: 1px solid #DDD;
    }

    caption {
      border: 1px dashed #DDD;
      border-bottom: 0;
      padding: 3px;
      text-align: center;
    }

    th {
      border-top: 1px solid #BBB;
      background-color: #F7F7F7;
    }

    table tr.firstRow th {
      border-top-width: 2px;
    }

    .ue-table-interlace-color-single {
      background-color: #fcfcfc;
    }

    .ue-table-interlace-color-double {
      background-color: #f7faff;
    }

    td p {
      margin: 0;
      padding: 0;
    }
  }
</style>
